import React from "react";
import {Box, CircularProgress, Paper, Typography} from "@mui/material";

export const ConnectionIndicator = (
    {
        // @ts-ignore
        isConnected
    }
) => {
    return (
        <div>
            <Box
                display={isConnected ? "none" : "flex"}
                position={"absolute"}
                top={"2vh"}
                right={"2vh"}
                padding={"1vh"}
                alignItems={"center"}
            >
                <Paper style={{display: "flex", padding: "1vh 1vw", alignItems: "center"}} elevation={8}>
                    <Typography color={"error"} fontSize={"2vh"}>
                        <b>Нет соединения</b>
                    </Typography>
                    <Box margin={"0.5vh 0 0.5vh 1vw"}>
                        <CircularProgress color={"error"} size={"3vh"}/>
                    </Box>
                </Paper>
            </Box>
        </div>
    );
}