import React, {useEffect, useState} from 'react';
import {Box, Divider, IconButton, Link} from "@mui/material";
import {Window} from "../window/Window";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import {Delete} from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/joy/Button";

const OpenGraphWindow = ({active, setActive, graphs, onGraphDelete}: {
                             active: boolean,
                             setActive: (active: boolean) => void,
                             graphs: string[],
                             onGraphDelete: (graph: string) => void
                         }
) => {
    const [deleteGraphOpened, setDeleteGraphOpened] = useState(false);
    const [graphToDelete, setGraphToDelete] = useState("");
    const handleClose = () => {
        setDeleteGraphOpened(false);
    };
    const handleDelete = () => {
        onGraphDelete(graphToDelete);
        setDeleteGraphOpened(false);
    }
    return (
        <Box>
            <Window active={active} setActive={setActive}>
                <Paper style={{backgroundColor: "#f8f8f8"}}>
                    <Box
                        margin={"20px"}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        gap={"10px"}
                        width={window.innerWidth > 700 ? "40vw" : "80vw"}
                    >
                        <Box width={"100%"} display={"grid"} gap={"10px"}>
                            <Typography variant={"h5"}>
                                Открыть граф
                            </Typography>
                            <Divider style={{width: "100%"}}/>
                        </Box>
                        <Paper style={{backgroundColor: "white", width: "100%"}}>
                            <Box minHeight={"300px"} maxHeight={"70vh"} padding={"20px"} width={"100%"} display={"grid"}
                                 overflow={"auto"}>
                                {graphs.length ?
                                <List>
                                    {
                                        graphs.map(
                                            (g: string) => (
                                                <Stack direction={"row"}>
                                                    <Link underline={"none"} href={`/visual?graphName=${g}`}
                                                          target={"_blank"} style={{width: "100%"}}>
                                                        <ListItemButton>
                                                            <Typography color={"black"}>{g}</Typography>
                                                        </ListItemButton>
                                                    </Link>
                                                    <IconButton onClick={() => {
                                                        setDeleteGraphOpened(true);
                                                        setGraphToDelete(g);
                                                    }}>
                                                        <Delete/>
                                                    </IconButton>
                                                </Stack>
                                            )
                                        )
                                    }
                                </List>
                                    :
                                    "Пока не сохранено ни одного графа"
                                }
                            </Box>
                        </Paper>
                    </Box>
                </Paper>
            </Window>
            <Dialog
                open={deleteGraphOpened}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Вы уверены что хотите удалить <i>{graphToDelete}</i>?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} color={"danger"}>
                        Нет
                    </Button>
                    <Button onClick={handleDelete} autoFocus variant={"outlined"}>
                        Да
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default OpenGraphWindow;