// webgraph
import Settings from "./settings";

const _auth_base_url = Settings.auth.baseUrl
const LOGIN_URL = `${_auth_base_url}/tokens`
const REFRESH_TOKEN_URL = `${_auth_base_url}/refresh`
const LOGOUT_URL = `${_auth_base_url}/logout`
const GET_ME_URL = `${_auth_base_url}/me`

// storage
const USER_LOCALSTORAGE_KEY = "user"

export {
    LOGIN_URL,
    GET_ME_URL,
    LOGOUT_URL,
    REFRESH_TOKEN_URL,
    USER_LOCALSTORAGE_KEY
}