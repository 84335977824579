import React from "react";
import MobileNav from "./MobileNav";
import Navbar from "./Navbar";
import "./header.css"
import {Box} from '@mui/material';
import AccountActionsButton from "../login/AccountActionsButton";
import Stack from "@mui/material/Stack";

const Header = (props: { additionalFunctions: any, children: any}) => {
    return (
        <header>
            <Box className="nav-area" position={"relative"} alignItems={"center"}>
                <Navbar additionalFunctions={props.additionalFunctions}/>
                <MobileNav additionalFunctions={props.additionalFunctions}/>
                <Stack direction={"row"} alignItems={"center"} position={"absolute"} right={0} margin={"0 1vw"}>
                    {props.children}
                </Stack>
            </Box>
        </header>
    );
};

export default Header;
