// @ts-nocheck

import {v4 as uuid4} from "uuid";
import {validate} from "jsonschema";
import validation from "./utils/validation";
import {FromSchema} from "json-schema-to-ts";
import {JrpcClient} from "./frontend/0_0_1/types";

export class JrpcController {
    _ws: WebSocket = null;
    _responses: {
        [k: string]:
            {
                resolve: (data: unknown) => void,
                reject: (err: unknown) => void
            }
    };
    // @ts-expect-error qwerty
    _visualizer: JrpcClient;

    constructor() {
        this._responses = {};
    }
    setWebSocket(ws: WebSocket | null) {
        this._ws = ws
    }
    setVisualizer(visualizer: JrpcClient) {
        this._visualizer = visualizer
    }

    /**
     * Вызов метода сервера
     */
    async doRpc(method: string, params: never[], withReturn: boolean = false): Promise<unknown> {
        if (this._ws === null || this._ws.readyState !== WebSocket.OPEN) {
            return;
        }
        method = method.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
        if (withReturn) {
            return await this._doRpcWithReturn(method, params)
        } else {
            return await this._doRpcNoReturn(method, params)
        }
    }

    async _doRpcWithReturn(method: string, params: never[]): Promise<unknown> {
        const id = uuid4();
        const msg = {jsonrpc: '2.0', id, method, params}
        this._ws.send(JSON.stringify(msg))
        return new Promise((resolve, reject) => {
            this._responses[id] = {
                resolve: (data: unknown) => resolve(data),
                reject: (err: unknown) => reject(err)
            }
        })
    }

    async _doRpcNoReturn(method: string, params: never[]): Promise<void> {
        const msg = {jsonrpc: '2.0', method, params}
        this._ws.send(JSON.stringify(msg))
    }

    async accept(msg: string): Promise<void> {
        let data;
        try {
            data = JSON.parse(msg);
        } catch (e) {
            this._ws.send(JSON.stringify(
                {jsonrpc: '2.0', id: null, error: {code: -32700, message: `Invalid JSON: ${msg}`}}
            ));
            return;
        }


        if (validate(data, validation.Res).valid) {
            this._onRes(data);
        } else if (validate(data, validation.Notif).valid) {
            this._onNotif(data)
        } else if (validate(data, validation.Req).valid) {
            this._onReq(data)
        } else if (validate(data, validation.Err).valid) {
            this._onErr(data)
        } else {
            if (data.id) {
                this._ws.send(JSON.stringify(
                    {jsonrpc: '2.0', id: data.id, error: {code: -32600, message: `Invalid Request`}}
                ));
            }
        }
    }

    /**
     * Обработка уведомления
     * @param {{ method: string | number; params: any; }} data
     */
    _onNotif (data: FromSchema<typeof validation.Notif>) {
        if (!this._visualizer[data.method]) {
            this._ws.send(JSON.stringify(
                {jsonrpc: '2.0', id: data.id, error: {code: -32601, message: `Method not found`}}
            ));
            return;
        }
        this._visualizer[data.method](data.params)
    }

    /**
     * Обработка ответа с сервера
     * @param {{ id: string | number; result: any; }} data
     */
    _onRes(data: FromSchema<typeof validation.Res>) {
        if (!this._responses[data.id]) {
            return
        }
        this._responses[data.id].resolve(data.result);
        delete this._responses[data.id];
    }


    /**
     * Обработка метода
     * @param {{ method: string | number; params: any; id: string }} data
     */
    _onReq = async (data: FromSchema<typeof validation.Req>) => {
        if (!this._visualizer[data.method]) {
            this._ws.send(JSON.stringify(
                {jsonrpc: '2.0', id: data.id, error: {code: -32601, message: `Method not found`}}
            ));
            return;
        }
        try {
            const func = this._visualizer[data.method]
            const result = func(data.params)
            if (result instanceof Promise) {
                await result;
            }
            this._ws.send(JSON.stringify({jsonrpc: '2.0', id: data.id, result}));
        } catch (e) {
            this._ws.send(JSON.stringify(
                {jsonrpc: '2.0', id: data.id, error: {code: -32603}}
            ));
        }
    }


    /**
     * Обработка ошибки
     * @param {{ id: string | number; error: any; }} data
     */
    _onErr(data: FromSchema<typeof validation.Err>) {
        this._responses[data.id]?.reject(data.error);
    }
}