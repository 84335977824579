import {USER_LOCALSTORAGE_KEY} from "../../constants";
import {User} from "../../global";

class TokenService {
  getLocalRefreshToken(): string | null {
    const user = this.getUser()
    if (user === null) return null;
    return user.refresh;
  }

  getLocalAccessToken(): string | null {
    const user = this.getUser()
    if (user === null) return null;
    return user.access;
  }

  getUser(): User | null {
    const userJsonString = localStorage.getItem(USER_LOCALSTORAGE_KEY)
    if (userJsonString === null) return null;
    return JSON.parse(userJsonString);
  }

  updateLocalAccessToken(token: string) {
    const user = this.getUser()
    if (user === null) return null;
    user.access = token;
    localStorage.setItem(USER_LOCALSTORAGE_KEY, JSON.stringify(user));
  }

  setUser(user: User) {
    window.user = user;
    localStorage.setItem(USER_LOCALSTORAGE_KEY, JSON.stringify(user));
  }

  removeUser() {
    window.user = null;
    localStorage.removeItem(USER_LOCALSTORAGE_KEY);
  }
}

export default new TokenService();