import api from "./api";
import TokenService from "./token.service";
import {LOGIN_URL, LOGOUT_URL} from "../../constants";

class AuthService {

    async login(props: { username: string, password: string }) {
        const {username, password} = props;
        const loginFormData = new FormData();
        loginFormData.append("username", username)
        loginFormData.append("password", password)
        const response = await api({
            url: LOGIN_URL,
            method: "post",
            data: loginFormData,
            headers: { "Content-Type": "multipart/form-data" }
        });
        if (response.data.access_token) {
            TokenService.setUser({access: response.data.access_token, refresh: response.data.refresh_token, username});
        }
        return response.data;
    }

    logout() {
        return api
            .post(
                LOGOUT_URL,
                TokenService.getUser()
            )
            .then(
                res => {
                    TokenService.removeUser();
                    sessionStorage.removeItem("visual_session_id")
                    return true;
                },
                err => {
                    return false
                }
            )

    }

    getCurrentUser() {
        return TokenService.getUser();
    }
}

export default new AuthService();