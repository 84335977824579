import React from "react";
import MenuItems from "./MenuItems";


const Dropdown = (
    props: {
        submenu: any,
        dropdown: boolean,
        depthLevel: number
    }
) => {
    const {submenu, dropdown, depthLevel} = props;
    const newDepthLevel = depthLevel + 1;
    const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";

    return (
        <ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
            {submenu.map((submenu: any, index: any) => (
                <MenuItems menuItem={submenu} key={index} depthLevel={newDepthLevel}/>
            ))}
        </ul>
    );
};

export default Dropdown;
