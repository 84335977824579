import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import Api from "../../services/http/api";
import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import UpdateIcon from '@mui/icons-material/Update';


function getGroupById(groups: any[], groupId: string) { 
  const group = groups.find((g: any) => g.id === groupId);
  return group;
}


export default function GroupGrid() {

  const [rows, setRows] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
  const [editMode, setEditMode] = useState(false);
  const api_connector = Api;
  
  useEffect(() => {
    try {
      api_connector.get('http://158.160.99.23/api/v1/access_groups/?page=1&size=30').then((res: any) => {
        setRows(res.data.results);
      })
    } catch (e) {
      console.log(e);
    }
    return () => {
    };
  }, []);


  const addNewRow = () => {
    setEditMode(false);
    setOpen(true);
  };

  const updateRow = () => {
    setEditMode(true);
    if (selectedGroupId !== null) {
      setNewGroupName(getGroupById(rows, selectedGroupId).name);
      setOpen(true);  
    }
  }

  const handleClose = () => {
    setNewGroupName('');
    setOpen(false);
  };

  const handleDeleteClick = (id: any) => async () => {
    try {
      await api_connector.delete('http://158.160.99.23/api/v1/access_groups/' + id);
      const res = await api_connector.get('http://158.160.99.23/api/v1/access_groups/?page=1&size=30');
      setRows(res.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  async function handleAddGroup() {
    if (selectedGroupId !== null && editMode) {
      try {
        await api_connector.put('http://158.160.99.23/api/v1/access_groups/' + selectedGroupId, {
          name: newGroupName,
        });
        const res = await api_connector.get('http://158.160.99.23/api/v1/access_groups/?page=1&size=30');
        setRows(res.data.results);
        setOpen(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Rethrow the error to be caught by the caller
      }
    }
    else {
      try {
        await api_connector.post('http://158.160.99.23/api/v1/access_groups/', {
          name: newGroupName,
        });
        const res = await api_connector.get('http://158.160.99.23/api/v1/access_groups/?page=1&size=30');
        setRows(res.data.results);
        setOpen(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Rethrow the error to be caught by the caller
      }
    }
  }

  const columns: GridColDef[] = [
    { 
      field: 'id', 
      headerName: 'ID', 
      editable: false,
      minWidth: 150
    },
    { 
      field: 'name', 
      headerName: 'Имя группы', 
      editable: false,
      minWidth: 150
    },
    {
      field: 'actions',
      headerName: 'Действие',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => (
        <IconButton
          aria-label="delete"
          onClick={handleDeleteClick(params.row.id)}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
      <Box>
        <Stack direction="row" spacing={1}>
          <Button color="primary" startIcon={<AddIcon />} onClick={addNewRow}>
            Добавить группу
          </Button>
          <Button color="primary" startIcon={<UpdateIcon />} onClick={updateRow}>
            Обновить группу
          </Button>
        </Stack>
        <div style={{ width: '100%', height: '60vh'}}>
          <DataGrid
            rows={rows}
            columns={columns}
            onRowSelectionModelChange={(itm: any) => setSelectedGroupId(itm[0])}
            disableMultipleRowSelection={true}
            hideFooter
          />
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{selectedGroupId !== null && editMode ? 'Обновить группу' : 'Добавить новую группу'}</DialogTitle>
          <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                label="Имя группы"
                fullWidth
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Отмена</Button>
              <Button onClick={handleAddGroup} color="primary">{selectedGroupId !== null && editMode ? 'Обновить' : 'Добавить'}</Button>
          </DialogActions>
          </Dialog>
      </Box>

  );
}
