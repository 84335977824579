import * as React from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import {
  DataGrid,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import Api from "../../services/http/api";
import { Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import UpdateIcon from '@mui/icons-material/Update';

function getUserbyId(users: any[], userId: string) { 
  const user = users.find((u: any) => u.id === userId);
  return user;
}

export default function UsersGrid() {

  const [rows, setRows] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [newUserName, setNewUserName] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [newUserIsAdmin, setNewUserIsAdmin] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [editMode, setEditMode] = useState(false);
  const api_connector = Api;
  
  useEffect(() => {
    try {
      api_connector.get('http://158.160.99.23/api/v1/users/?page=1&size=30').then((res: any) => {
        setRows(res.data.results);
      })
    } catch (e) {
      console.log(e);
    }
    return () => {
    };
  }, []);


  const addNewRow = () => {
    setEditMode(false);
    setOpen(true);
  };

  const updateRow = () => {
    setEditMode(true);
    if (selectedUserId !== null) {
      setNewUserName(getUserbyId(rows, selectedUserId).username);
      setNewUserIsAdmin(getUserbyId(rows, selectedUserId).administration);
      setOpen(true);  
    }
  }

  const handleClose = () => {
    setOpen(false);
    setNewUserName('');
    setNewUserIsAdmin(false);
    setSelectedUserId(null);
  };

  const handleDeleteClick = (id: any) => async () => {
    try {
      await api_connector.delete('http://158.160.99.23/api/v1/users/' + id);
      const res = await api_connector.get('http://158.160.99.23/api/v1/users/?page=1&size=30');
      setRows(res.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  async function handleAddUser() {
    if (selectedUserId !== null && editMode) {
      try {
        await api_connector.put('http://158.160.99.23/api/v1/users/' + selectedUserId, {
          username: newUserName,
          password: newUserPassword,
          administration: newUserIsAdmin
        });
        const res = await api_connector.get('http://158.160.99.23/api/v1/users/?page=1&size=30');
        setRows(res.data.results);
        setOpen(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Rethrow the error to be caught by the caller
      }
    }
    else {
      try {
        console.log({username: newUserName, administration: newUserIsAdmin, password: newUserPassword});
        await api_connector.post('http://158.160.99.23/api/v1/users/', {
          username: newUserName,
          administration: newUserIsAdmin,
          password: newUserPassword,
        });
        const res = await api_connector.get('http://158.160.99.23/api/v1/users/?page=1&size=30');
        setRows(res.data.results);
        setOpen(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Rethrow the error to be caught by the caller
      }
    }
  }

  const columns: GridColDef[] = [
    { 
      field: 'id', 
      headerName: 'ID', 
      editable: false,
      minWidth: 150,
    },
    { 
      field: 'username', 
      headerName: 'Имя пользователя', 
      editable: false,
      minWidth: 150,
    },
    {
      field: 'administration',
      headerName: 'Администратор',
      editable: false,
      renderCell: (params: any) => (
        <Checkbox
          checked={params.value}
          onChange={(event: any) => {
            const newValue = event.target.checked;
            // Handle change logic here, e.g., update row data
          }}
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Действие',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => (
        <IconButton
          aria-label="delete"
          onClick={handleDeleteClick(params.row.id)}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
      <Box>
        <Stack direction="row" spacing={1}>
          <Button color="primary" startIcon={<AddIcon />} onClick={addNewRow}>
            Добавить пользователя
          </Button>
          <Button color="primary" startIcon={<UpdateIcon />} onClick={updateRow}>
            Изменить пользователя
          </Button>
        </Stack>
        <div style={{ width: '100%', height: '60vh'}}>
          <DataGrid
            rows={rows}
            columns={columns}
            onRowSelectionModelChange={(itm: any) => setSelectedUserId(itm[0])}
            disableMultipleRowSelection={true}
            hideFooter
          />
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{selectedUserId !== null && editMode ? 'Обновить пользователя' : 'Добавить нового пользователя'}</DialogTitle>
          <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                label="Имя пользователя"
                fullWidth
                value={newUserName}
                onChange={(e) => setNewUserName(e.target.value)}
            />
            <TextField
                autoFocus
                margin="dense"
                label="Пароль"
                fullWidth
                value={newUserPassword}
                onChange={(e) => setNewUserPassword(e.target.value)}
            />
            <FormControlLabel
            labelPlacement="start"
            label="Администратор " 
            control={<Checkbox
              value={newUserIsAdmin}
              onChange={(e) => setNewUserIsAdmin(e.target.checked)}
              />} 
            />
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Отмена</Button>
              <Button onClick={handleAddUser} color="primary">{selectedUserId !== null && editMode ? 'Обновить' : 'Добавить'}</Button>
          </DialogActions>
          </Dialog>
      </Box>

  );
}
