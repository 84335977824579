import React, {useEffect, useRef, useState} from "react";
import Dropdown from "./Dropdown";
import {Link} from "react-router-dom";

const MenuItems = (
    props: {
        menuItem: any,
        depthLevel: number
    }
) => {
    const {menuItem, depthLevel} = props;
    const [dropdown, setDropdown] = useState(false);
    const ref: React.MutableRefObject<HTMLLIElement | undefined> = useRef();

    useEffect(() => {
        const handler = (event: Event): any => {
            // @ts-ignore
            if (dropdown && ref.current && !ref.current.contains(event.target)) {
                setDropdown(false);
            }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, [dropdown]);

    const onMouseEnter = () => {
        setDropdown(true);
    };

    const onMouseLeave = () => {
        setDropdown(false);
    };

    const toggleDropdown = () => {
        setDropdown((prev) => !prev);
    };

    const closeDropdown = () => {
        dropdown && setDropdown(false);
    };

    const onClickHandler = () => {
        console.log(menuItem);
        if (menuItem.function) {
            menuItem.function()
        }
    }

    return (
        <li
            className="menu-items"
            // @ts-ignore
            ref={ref}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={closeDropdown}>
            {menuItem.url && menuItem.submenu ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? "true" : "false"}
                        onClick={() => toggleDropdown()}>
                        {menuItem.url ? menuItem.title : <Link to={menuItem.url}>{menuItem.title}</Link>}
                        {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow"/>}
                    </button>
                    <Dropdown
                        depthLevel={depthLevel}
                        submenu={menuItem.submenu}
                        dropdown={dropdown}
                    />
                </>
            ) : !menuItem.url && menuItem.submenu ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? "true" : "false"}>
                        {menuItem.title}
                        {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow"/>}
                    </button>
                    <Dropdown
                        depthLevel={depthLevel}
                        submenu={menuItem.submenu}
                        dropdown={dropdown}
                    />
                </>
            ) : (
                <Link to={menuItem.url + location.search} onClick={() => onClickHandler()} target={menuItem.target}>{menuItem.title}</Link>
            )}
        </li>
    );
};

export default MenuItems;
