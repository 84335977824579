import {useEffect, useState} from "react";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import GroupsAndUsers from "./GroupsAndUsers";
import { NodeCard } from "./NodeCard";
import { VisualConfig } from "./VisualConfig";
import { NodeIcon } from "./NodeIcon";
import { DispalyConfig } from "./DisplayConfig";

export const ConfigPage = () => {
    useEffect(() => {
        document.title = 'Config Page';
    }, []);
    const [selectedItem, setSelectedItem] = useState<string | null>('Группы и пользователии');

    const menuContent: { [key: string]: any } = {
        'Администрирование': '',
        'Группы и пользователии': <GroupsAndUsers />,
    };

    const handleMenuItemClick = (item: string) => {
        if (item === 'Администрирование') {
            window.location.href = 'http://51.250.87.244:8012';
        }
        else {
            setSelectedItem(item);
        }    
    };

    
    return (
        <div style={{ display: 'flex' }}>
            <div style={{ width: '258px' }}>
                <Drawer
                variant="permanent"
                >
                <List component="nav" aria-label="secondary mailbox folder">
                    {Object.keys(menuContent).map((text, index) => (
                        <ListItemButton key={text} onClick={() => handleMenuItemClick(text)} selected={selectedItem === text}>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    ))}
                </List>
                </Drawer>
            </div>
            <div style={{ flex: 1, maxWidth: 'calc(100vw - 258px)' }}>    
                <main>
                    {selectedItem ? menuContent[selectedItem] : <p>'Выберите пункт меню'</p>}
                </main>
            </div>
        </div>
    );
}

