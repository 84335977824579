interface MenuItem {
  title: string;
  url?: string;
  target?: string;
  submenu?: MenuItem[];
}

interface MenuItemWithFunction extends MenuItem {
  function?: any | null;
  functionData?: any | null;
}

export const menuItemsData: MenuItem[] = 
[
  {
    title: "Меню",
    url: "",
    target: "",
    submenu: [
      {
        title: "Создать",
        url: "",
        target: "_blank",
      },
      {
        title: "Сохранить",
        url: "",
        target: "",
      },
      {
        title: "Сохранить как",
        url: "",
        target: "",
      },
      {
        title: "Сохранить в Excel",
        url: "",
        target: "",
      },
      {
        title: "Открыть",
        url: "",
        target: "",
      },
      {
        title: "Удалить",
        url: "",
        target: "",
      },
      {
        title: "Настройки",
        url: "/configuration",
        target: "_blank",
      }
    ]
  },
  {
    title: "Вид",
    url: "#",
    target: "",
    submenu: 
    [
      {
        title: "Выделить все",
        url: "#",
        target: "",
      },
      {
        title: "Выделить связанные объекты",
        url: "#",
        target: "",
      },
      {
        title: "Отобразить все",
        url: "#",
        target: "",
      },
      {
        title: "Скрыть все",
        url: "#",
        target: "",
      },
      {
        title: "Скрыть выделенное",
        url: "#",
        target: "",
      },
      // {
      //   title: "Сгруппировать по типам",
      //   url: "#",
      //   target: "",
      // },
      // {
      //   title: "Визуальное группирование",
      //   url: "#",
      //   target: "",
      // }
    ],
  },
  // {
  //   title: "Действие",
  //   url: "#",
  //   target: "",
  //   submenu: [
  //     {
  //       title: "Проверки",
  //       url: "#",
  //       target: "",
  //       submenu: [
  //         {
  //           title: "Поиск по реестру   ",
  //           url: "#",
  //           target: "",
  //         }
  //       ]
  //     },
  //     {
  //       title: "Извлечь все реквизиты",
  //       url: "#",
  //       target: "",
  //     },
  //     {
  //       title: "Установить значимость",
  //       url: "#",
  //       submenu: [
  //         {
  //           title: "Высокая",
  //           url: "#",
  //           target: "",
  //         },
  //         {
  //           title: "Средняя",
  //           url: "#",
  //           target: "",
  //         },
  //         {
  //           title: "Низкая",
  //           url: "#",
  //           target: "",
  //         },
  //       ]
  //     }
  //   ]
  // },
];



export default function mergeMenuItemsWithFunctions(menuItems: MenuItem[], functions: { title: string, function: any, functionData: any }[]): MenuItemWithFunction[] {
    const mergedMenuItems: MenuItemWithFunction[] = [];

    menuItems.forEach(menuItem => {
        const mergedItem: MenuItemWithFunction = { ...menuItem, function: null, functionData: null};

        const matchingFunction = functions.find(func => func.title === menuItem.title);
        if (matchingFunction) {
            mergedItem.function = matchingFunction.function;
            mergedItem.functionData = matchingFunction.functionData;
        }

        if (menuItem.submenu) {
            mergedItem.submenu = mergeMenuItemsWithFunctions(menuItem.submenu, functions);
        }

        mergedMenuItems.push(mergedItem);
    });

    return mergedMenuItems;
}