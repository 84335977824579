import React, {useState} from 'react';
import Settings from "../settings";
import {JrpcController} from "../jrpc/controller";
import {createJrpcServerClientInstance} from "../jrpc/server/serverClient";
import {v4} from "uuid";
import GraphVisual from "../components/graph/GraphPlot";
import {ConnectionIndicator} from "../components/connection/ConnectionIndicator";
import {Box} from "@mui/material";

import TokenService from "../services/http/token.service";
import {useSearchParams} from "react-router-dom";

const WS_URL = Settings.getWebsocketAddress()
const jrpcController = new JrpcController();
const jrpcServerClient = createJrpcServerClientInstance(jrpcController);

export const VisualPage = () => {
    const [wsConnected, setWsConnected] = React.useState(true)
    const [query, setQuery] = useSearchParams()
    document.title = 'Visual';
    const [notificationInfo, changeNotificationState] = useState(
        {
            open: false,
            text: '',
            code: 0,
        }
    );

    function connectWebsocket() {
        if (!sessionStorage.getItem("visual_session_id")) {
            sessionStorage.setItem("visual_session_id", v4())
        }
        const wsUrl = `${WS_URL}/${sessionStorage.getItem("visual_session_id")}`
        window.ws = new WebSocket(wsUrl);
        window.timeoutId = setTimeout(function () {
            if (window?.ws.readyState !== WebSocket.OPEN) {
                setWsConnected(false)
            }
        }, 3000);

        window.ws.onopen = () => {
            const token = TokenService.getLocalAccessToken()
            const initialData = {
                authorization: token ? `Bearer ${token}` : null,
                session_id: sessionStorage.getItem("visual_session_id"),
                graph_name: query.get('graphName')
            }
            console.log(initialData)
            window.ws.send(JSON.stringify(initialData))
            console.log(`Connected to ${wsUrl}`)
            clearTimeout(window.timeoutId)
            setWsConnected(true)
            window.graph.clear();
            window.graph.moveTo(window.graph.getWidth() / 2, window.graph.getHeight() / 2)
            window.graph.zoom(0.75)
            jrpcController.setWebSocket(window.ws)
        }

        window.ws.onclose = (closeEvent: CloseEvent) => {
            if (closeEvent.code === 1008) {
                changeNotificationState({code: 1, open: true, text: 'Авторизация не удалась'})
                TokenService.removeUser();
                query.delete('graphName')
                setQuery(query)
            }
            jrpcController.setWebSocket(null)
            setTimeout(function () {
                connectWebsocket();
            }, 1000);
            window.timeoutId = setTimeout(function () {
                if (window?.ws.readyState !== WebSocket.OPEN) setWsConnected(false)
            }, 3000)

        }

        window.ws.onmessage = async (event: { data: string; }) => {
            await jrpcController.accept(event.data)
        }

        window.ws.onerror = e => {
            console.log(e)
            window.ws.close()
        }
    }

    React.useEffect(() => {
        if (window.ws === undefined) {
            connectWebsocket()
        }
    }, [])

    return (
        <Box>
            <ConnectionIndicator isConnected={wsConnected}/>
            <GraphVisual
                jrpcController={jrpcController}
                jrpcServerClient={jrpcServerClient}
                notificationInfo={notificationInfo}
                changeNotificationState={changeNotificationState}
            />
        </Box>
    );
};