import React from 'react';
import {Box} from "@mui/material";
import {Window} from "../window/Window";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Divider} from "@mui/joy";
import TextField from "@mui/material/TextField";

const SaveGraphWindow = (
    {active, setActive, saveHandler, initName}:
        {
            active: boolean,
            setActive: (active: boolean) => void,
            saveHandler: (props: { name: string }) => void,
            initName: string
        }
) => {
    const [name, setName] = React.useState(initName);
    return (
        <Box>
            <Window
                active={active}
                setActive={setActive}
            >
                <Paper sx={{backgroundColor: "#f8f8f8", padding: "20px"}}>
                    <Box width={window.innerWidth > 700 ? "40vw" : "80vw"}>
                        <Box display={"grid"} justifyItems={"start"}>
                            <Box padding={"10px"}>
                                <Typography variant={"h5"}>
                                    Сохранить граф как...
                                </Typography>
                            </Box>
                            <Divider style={{width: "100%"}}/>
                        </Box>
                        <Box padding={"40px 10px"} display={"grid"} gap={"20px"}>
                            <TextField
                                placeholder={"Название"}
                                fullWidth
                                autoFocus
                                value={name}
                                onChange={e => {
                                    setName(e.target.value)
                                }}
                            />
                            <Button type={"submit"} variant={"contained"} onClick={() => saveHandler({name})}>
                                Сохранить
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Window>
        </Box>
    );
};

export default SaveGraphWindow;