import React, {useEffect, useState} from 'react';
import LoginIcon from '@mui/icons-material/Login';
import {Box, Divider, IconButton, Stack, Typography} from '@mui/material';
import TokenService from "../../services/http/token.service";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoginWindow from "./LoginWindow";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AuthService from "../../services/http/auth.service";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';


const AccountActionsButton = () => {
    const [user, setUser] = useState(false);
    const [loginWindowOpened, setLoginWindowOpened] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [logoutAlarmOpened, setLogoutAlarmOpened] = useState(false);

    useEffect(() => {
        setUser(window.user !== null);
    }, [window.user]);

    useEffect(() => {
        window.user = TokenService.getUser();
    }, []);

    function handleLoginClick() {
        setLoginWindowOpened(true)
    }

    function handleAccountClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }

    async function handleLogoutButton() {
        setLogoutAlarmOpened(true)
    }

    async function handleLogoutAgree() {
        const userLogoutOk = await AuthService.logout();
        if (userLogoutOk) window.location.reload()
        else alert("Не удалось выйти")
        handleLogoutClose()
    }

    function handleLogoutClose() {
        setLogoutAlarmOpened(false)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <LoginWindow
                active={loginWindowOpened}
                setActive={setLoginWindowOpened}
            />
            {
                user ?
                    <IconButton onClick={handleAccountClick}>
                        <AccountCircleIcon/>
                    </IconButton>
                    :
                    <IconButton onClick={handleLoginClick}>
                        <LoginIcon/>
                    </IconButton>
            }
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem disabled sx={{minWidth: 150}}>
                    <Stack margin={"5px"} width={"100%"} alignItems={"center"}>
                        <Typography color={"black"}>
                            {window.user?.username || ""}
                        </Typography>
                    </Stack>
                </MenuItem>
                <Divider/>
                <MenuItem onClick={handleLogoutButton}>
                    <Typography color={"red"}>
                        Выйти
                    </Typography>
                </MenuItem>
            </Menu>
            <Dialog
                open={logoutAlarmOpened}
                onClose={handleLogoutClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Вы уверены что хотите выйти из аккаунта?"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleLogoutClose} autoFocus>
                        Нет, остаться
                    </Button>
                    <Button onClick={handleLogoutAgree} color={"error"}>
                        Выйти
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AccountActionsButton;
