import { Dialog, Button, DialogTitle, DialogContent, DialogActions } from '@mui/material';


const WarningDialog: React.FC<any> = ({ open, onClose, title, message}) => {
    return (
        <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <p>{message}</p>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>OK</Button>
        </DialogActions>
        </Dialog>
    );
};

export default WarningDialog;
