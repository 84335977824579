import * as React from 'react';
import {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import {Window} from "../window/Window";
import { Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AuthService from "../../services/http/auth.service";

interface IAlert {
    open: boolean;
    data: string;
    type: "error"|"success";
}

export default function LoginWindow(props: { active: boolean, setActive: (active: boolean) => void }) {
    const {active, setActive} = props;
    const [alert, setAlert] = useState<IAlert>({open:false, data:"", type:"error"});
    const [loginInProgress, setLoginInProgress] = useState(false);

    const handleIncorrectCredentails = (statusCode: number) => {
        let message = "";
        if (statusCode === 401){
            message = "Неверный логин или пароль";
        } else {
            message = "Неизвестная ошибка при авторизации";
        }
        setAlert({open:true, data: message, type:"error"});
        setLoginInProgress(false);
    };

    const handleSuccessLogin = () => {
        setLoginInProgress(false);
        setActive(false);
        setAlert({open:true, data: "Успешная авторизация", type: "success"});
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoginInProgress(true);
        const data = new FormData(event.currentTarget);
        const username = data.get('username') as string;
        const password = data.get('password') as string;
        AuthService.login({username, password})
            .then(res=>window.location.reload())
            .then(()=>handleSuccessLogin())
            .catch(error => handleIncorrectCredentails(error.response?.status || 400));
    };

    return (
        <>
        {alert.open && setInterval(() => setAlert({...alert, open:false}), 5000) && (
        <Alert
          severity={alert.type}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() =>setAlert({...alert, open:false})}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ position: 'fixed', bottom: 10, right: 10, zIndex: 1000 }}
        >{alert.data}</Alert>)}
            <Window active={active} setActive={setActive}>
                <Paper>
                    <Box
                        sx={{
                            margin: "20px",
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: 'min(500px, 80vw)'
                        }}
                    >
                        <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                            {
                                loginInProgress ?
                                    <CircularProgress/>
                                    :
                                    <LockOutlinedIcon/>
                            }
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Вход
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Имя пользователя"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Пароль"
                                type="password"
                                id="password"
                                autoComplete="password"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                Войти
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Window>
        </>
    );
}