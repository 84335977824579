import React, {useEffect, useRef, useState} from "react";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MobileMenuItems from "./MobileMenuItems";
import mergeMenuItemsWithFunctions from "./MenuData";
import { menuItemsData } from "./MenuData";

const MobileNav = (props: {additionalFunctions: any}) => {
    const depthLevel = 0;
    const [showMenu, setShowMenu] = useState(false);
    const ref: React.MutableRefObject<HTMLUListElement | undefined> = useRef();
    const resultMenu = mergeMenuItemsWithFunctions(menuItemsData, props.additionalFunctions);

    useEffect(() => {
        const handler = (event: Event) => {
            // @ts-ignore
            if (showMenu && ref.current && !ref.current.contains(event.target)) {
                setShowMenu(false);
            }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, [showMenu]);

    return (
        <nav className="mobile-nav">
            <button
                style={{ paddingRight: "30px" }}
                className="mobile-nav__menu-button"
                type="button"
                onClick={() => setShowMenu((prev) => !prev)}>
                <MoreHorizIcon />
            </button>

            {showMenu && (
                // @ts-ignore
                <ul className="menus" ref={ref}>
                    {resultMenu.map((menu, index) => {
                        return (
                            <MobileMenuItems
                                menuItem={menu}
                                key={index}
                                depthLevel={depthLevel}
                                showMenu={showMenu}
                                setShowMenu={setShowMenu}
                            />
                        );
                    })}
                </ul>
            )}
        </nav>
    );
};

export default MobileNav;
