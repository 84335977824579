import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import Api from '../../services/http/api';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningDialog from './WarningDialog';



function getUserNamebyId(users: any[], userId: string) { 
  const user = users.find((u: any) => u.id === userId);
  return user ? user.username : '';
}

function getGroupNamebyId(groups: any[], groupId: string) { 
  const group = groups.find((g: any) => g.id === groupId);
  return group ? group.name : '';
}

function getAcessGroupByGroupId(accesGroups: any[], groupId: string) {
    const acsessGroup = '';
    for (let i = 0; i < accesGroups.length; i++) {
        if (accesGroups[i].access_group_id === groupId) {
            return accesGroups[i].id;
        }
    }
    return acsessGroup;
}

function isManager(groupId: string, userId: string): boolean {
  Api.get(`http://158.160.99.23/api/v1/users/${userId}/access_groups`).then((res) => {
    const userAccGroup = res.data;
    for (let i = 0; i < userAccGroup.length; i++) {
      if (userAccGroup[i].access_group_id === groupId) {
        return userAccGroup[i].management;
      }
    }
  });
  return false;
}

const GroupManagementPage: React.FC = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [groups, setGroups] = useState<any[]>([]);
  const [groupUsersMap, setGroupUsersMap] = useState<{ [groupId: string]: any[] }>({});
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [selectedGroup, setSelectedGroup] = useState<string>('');
  const [openWarning, setOpenWarning] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [warningMessage, setWarningMessage] = React.useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  const fetchUsersAndGroups = async () => {
    try {
      const usersRes = await Api.get('http://158.160.99.23/api/v1/users/?page=1&size=30');
      const groupsRes = await Api.get('http://158.160.99.23/api/v1/access_groups/?page=1&size=30');
      const usersInGroupRes = await Api.get('http://158.160.99.23/api/v1/users_access_groups/?page=1&size=30');
      const tempGroupUsersMap: { [groupId: string]: any[] } = {};
      usersInGroupRes.data.results.forEach((item: any) => {
        const { access_group_id, user_id } = item;
        if (!tempGroupUsersMap[access_group_id]) {
            tempGroupUsersMap[access_group_id] = [user_id];
        } else {
            tempGroupUsersMap[access_group_id].push(user_id);
        }
      });
      setGroupUsersMap(tempGroupUsersMap);
      
      setUsers(usersRes.data.results);
      setGroups(groupsRes.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchUsersAndGroups();
  }, []);

  const handleUserClick = (userId: string) => {
    setSelectedUser(userId);
  };

  const handleGroupClick = (groupId: string) => {
    setSelectedGroup(groupId);
  };

  const handleAddUserToGroup = async () => {
    if (selectedUser && selectedGroup) {
      if (!(groupUsersMap[selectedGroup] && groupUsersMap[selectedGroup].includes(selectedUser))) {
        try {
          const addResult = await Api.post('http://158.160.99.23/api/v1/users_access_groups/', {
              user_id: selectedUser,
              access_group_id: selectedGroup,
              managment: isAdmin,
          });
          fetchUsersAndGroups();
        } catch (error) {
          console.log('Error adding user to group:', error);
        }
      } else {
        console.log('User already in group');
        setTitle('Предупреждение');
        setWarningMessage('Пользователь уже в группе');
        setOpenWarning(true);
      }
    }
};

  const handleRemoveUserFromGroup = async (userId: string) => {
    if (selectedGroup && groupUsersMap[selectedGroup]) {
      try {
        const userAccGroup =  await Api.get(`http://158.160.99.23/api/v1/users/${userId}/access_groups`);
        const deleteLinkId = getAcessGroupByGroupId(userAccGroup.data, selectedGroup);
        await Api.delete('http://158.160.99.23/api/v1/users_access_groups/' + deleteLinkId);
        fetchUsersAndGroups();
      } catch (error) {
        console.log('Error removing user from group:', error);
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px' }}>
      <WarningDialog open={openWarning} onClose={() => setOpenWarning(false)} title={title} message={warningMessage} />
      <Box sx={{ borderRight: '1px solid #ccc', paddingRight: '20px' }}>
        <Typography variant="h5">Пользователи</Typography>
        <List>
          {users.map((user: any) => (
            <ListItem
              key={user.id}
              button
              selected={selectedUser === user.id}
              onClick={() => handleUserClick(user.id)}
            >
              <ListItemText primary={user.username} />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box sx={{ margin: '0 20px' }}>
        <Typography variant="h5" style={{ marginBottom: '10px' }} >
          Добавить {selectedUser ? getUserNamebyId(users, selectedUser) + ' ' : 'пользователя' }
           в группу {getGroupNamebyId(groups, selectedGroup)}
        </Typography>
        <div style={{ marginBottom: '40px' }}>
          <FormControlLabel
              labelPlacement="start"
              label="Управление группой:" 
              control={<Checkbox
                onChange={(e) => setIsAdmin(e.target.checked)}
                />}
          />
        </div>
        <Button
          variant="contained"
          color="primary"
          disabled={!selectedUser || !selectedGroup}
          onClick={handleAddUserToGroup}
        >
          Добавить
        </Button>
      </Box>
      <Box sx={{ borderLeft: '1px solid #ccc', paddingLeft: '20px' }}>
        <Typography variant="h5">Группы</Typography>
        <List style={{ marginBottom: '30px' }}>
          {groups.map((group: any) => (
            <ListItem
              key={group.id}
              button
              selected={selectedGroup === group.id}
              onClick={() => handleGroupClick(group.id)}
            >
              <ListItemText primary={group.name} />
              <List style={{ 
                marginLeft: '10px',  
                border: groupUsersMap[group.id]?.length ? '1px solid #ccc' : 'none', 
                padding: '10px', 
                borderRadius: '5px'}}>
                {groupUsersMap[group.id] && groupUsersMap[group.id].map((userId: string) => (
                  <ListItem key={userId} sx={{ display: 'flex', alignItems: 'center' }}>
                    <ListItemText primary={`${getUserNamebyId(users, userId)}`} sx={{ flex: '1' }} />
                    <Checkbox disabled checked={isManager(group.id, userId)} />
                    <Button 
                      style={{ marginLeft: '10px', border: 'none' }}
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleRemoveUserFromGroup(userId)}
                      startIcon={<DeleteIcon />}
                    />
                  </ListItem>
                ))}
              </List>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default GroupManagementPage;
