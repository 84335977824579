import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import Textarea from '@mui/joy/Textarea';
import {Divider} from "@mui/joy";
import {Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import {Window} from "../window/Window";
import Paper from "@mui/material/Paper";

// @ts-ignore
export default function TextPasteInputWindow({active, setActive, sendHandler}) {
    const [value, setValue] = useState("");

    const _onSendClick = () => {
        setActive(false);
        sendHandler(value);
    };

    useEffect(() => {
        setValue("");
    }, [active]);

    return (<>
        <Window
            active={active}
            setActive={setActive}
        >
            <Paper
                style={{
                    padding: "10px 0",
                    backgroundColor: "#f8f8f8",
                }}
                sx={{borderRadius: "5px !important"}}
            >
                <Stack width={"100%"}>
                    <Box marginBottom={"5px"}>
                        <Typography variant={"h6"}>
                            Добавить текст на схему
                        </Typography>
                    </Box>
                </Stack>
                <FormControl>
                    <Textarea
                        placeholder="Текст для вставки..."
                        minRows={10}
                        maxRows={10}
                        required
                        value={value}
                        variant={"soft"}
                        onChange={(e) => setValue(e.target.value)}
                        autoFocus
                        sx={{
                            width: window.innerWidth > 700 ? "40vw" : "80vw",
                            backgroundColor: "white",
                            border: "1px solid #d0d9db",
                            borderRadius: 0
                        }}
                    />
                    <Stack width={"100%"} display={"grid"}>
                        <Box margin={"10px 10px 0 0"} justifySelf={"end"}>
                            <Button
                                disabled={value === ""}
                                onClick={_onSendClick}
                            >
                                Добавить
                            </Button>
                        </Box>
                    </Stack>
                </FormControl>
            </Paper>
        </Window>
    </>);
}
