export default class Settings {
    static client = {
        version: '0_0_1'
    };
    static server = {
        version: '0_0_1'
    };


    static getApiHost(port=8000) {
        const protocol = location.protocol;
        let hostname;
        if (location.host.endsWith(':3000')) {
            hostname = `${location.hostname}:${port}`
        } else {
            hostname = location.host
        }
        return `${protocol}//${hostname}`
    }

    static getWebsocketAddress() {
        return `${Settings.getApiHost(9000)}/graph/ws`.replace("http", "ws")
    }

    static auth = {
        baseUrl: "http://158.160.99.23/api/v1/auth"
    }
}
