// @ts-nocheck

/**
 * Ниже нужно номер актуальной версии схемы, TODO: сделать как-то динамический импорт если это возможно для типа
 */
import schema from './0_0_1/schema.json';
import {JrpcServer} from "./0_0_1/types";
import {JrpcController} from "../controller";

/**
 * Возвращает клиента для асинхронного общения с сервером.
 * Типизация опосредованно получается из актуальной версии схемы,
 * а при вызове любого из методов идет отправка jrpc-сообщения.
 */
export function createJrpcServerClientInstance(controller: JrpcController): JrpcServer {
    return new Proxy(
        controller,
        {
            get:
                function (target, prop: string) {
                    if (target[prop]) {
                        return target[prop]
                    } else {
                        return async function (kwargs: never) {
                            const withReturn = Object.prototype.hasOwnProperty.call(
                                // @ts-expect-error qwerty
                                schema.properties[prop].properties, 'return_type'
                            )
                            console.log(`JRPC: ${prop} ${JSON.stringify(kwargs)}`)
                            return await target.doRpc(prop, kwargs, withReturn)
                        }
                    }
                }
        }
    )
}
