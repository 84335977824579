import React from 'react';
import {Window} from "../window/Window";
import {Box, Button, Divider, IconButton, ListItem, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Paper from "@mui/material/Paper";
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import Settings from "../../settings";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const FILE_UPLOAD_POST_URL = `${Settings.getApiHost()}/api/v1/files/tmp`;

// @ts-ignore
export const FilesPasteWindow = (props: { active, setActive, onSend: (files: string[]) => void , accept: string}) => {
    const {active, setActive, onSend, accept} = props;
    const [files, setFiles] = React.useState([]);

    const handleWindowClosed = () => {
        setFiles([]);
        setActive(false);
    }

    const handleAddOnSchemeClick = () => {
        // @ts-ignore
        onSend(files.map(file=>file.id));
        handleWindowClosed()
    };

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const uploadFiles = event.target.files
        if (uploadFiles === null) {
            return
        }

        const uploadedFiles: object[] = []
        for (let i = 0; i < uploadFiles.length; i++) {
            const formData = new FormData()
            formData.append('file', uploadFiles[i])
            const response = await axios.post(FILE_UPLOAD_POST_URL, formData, {
                headers: {
                    'Content-Type': uploadFiles[i].type
                }
            })
            if (response.status >= 200 && response.status < 300) {
                uploadedFiles.push(response.data);
            }
        }
        // @ts-ignore
        setFiles(oldFiles=>[...oldFiles, ...uploadedFiles])
    }

    const FileItem = (props: { id: string, fileName: string }) => {
        const {fileName, id } = props
        const onRemoveClick = () => {
            // @ts-ignore
            setFiles(oldFiles=>{return oldFiles.filter(file=>file.id!==id)})
        }
        return (fileName && <>
            <ListItem disableGutters disablePadding style={{padding: "3px", width: "100%", wordBreak: "break-all"}}>
                <IconButton onClick={onRemoveClick}><CloseIcon/></IconButton>
                <Typography>{fileName}</Typography>
            </ListItem>
        </>)
    }

    return (
        <Window active={active} setActive={setActive} onClose={handleWindowClosed}>
            <Paper sx={{backgroundColor: "#f8f8f8", padding: "20px"}}>
                <Box width={window.innerWidth > 700 ? "40vw" : "80vw"} display={"flex"} flexDirection={"column"} gap={"20px"} alignItems={"start"}>
                    <Paper variant={"outlined"} style={{height: "50vh", width: "100%"}}>
                        {files.length > 0 && <>
                            <Box padding={"10px"}>
                                <Typography variant={"h6"}>Файлы:</Typography>
                            </Box>
                            <Divider/>
                        </>}
                        {
                            files.length === 0 ?
                                <Box height={"100%"}>
                                    <Box display={"grid"} alignItems={"center"} justifyItems={"center"} height={"100%"}>
                                        <Typography color={"gray"}>Для загрузки файла, нажмите кнопку ниже</Typography>
                                    </Box>
                                </Box>
                                :
                                <Box overflow={"auto"} height={"40vh"} width={"100%"}>
                                    {
                                        files.map(
                                            file => {
                                                // @ts-ignore
                                                return <FileItem
                                                    // @ts-ignore
                                                    fileName={file.filename}
                                                    // @ts-ignore
                                                    id={file.id}
                                                />
                                            }
                                        )
                                    }
                                </Box>
                        }
                    </Paper>
                    <Box width={"100%"}>
                    {/* <Box alignSelf={"end"}> */}
                        <Box display={"flex"} gap={"20px"}>
                            <Button
                                component="label"
                                role={undefined}
                                variant="outlined"
                                tabIndex={-1}
                                startIcon={<AttachFileIcon/>}
                                style={{textTransform: "none", height: "5em"}}
                                fullWidth
                                size={"large"}
                            >
                                Прикрепить файл
                                <VisuallyHiddenInput accept={accept} type="file" onChange={handleFileUpload} multiple/>
                            </Button>
                            <Button
                                variant={"contained"}
                                style={{textTransform: "none", justifySelf: "right"}}
                                endIcon={<CloudUploadIcon/>}
                                onClick={handleAddOnSchemeClick}
                                disabled={files.length === 0}
                            >
                                Добавить {files.length} файл{files.length === 1 ? "" : files.length > 4 ? "ов" : "а"} на
                                схему
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Window>
    );
};
