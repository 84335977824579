import React, {useEffect, useState} from "react";
import { Tabs, Tab, Box, Checkbox } from '@mui/material';
import UsersGrid from './UsersGrid'; // Import your DataGrid components
import GroupsGrid from './GroupsGrid';
import UserManagementPage from './GroupsManagment';
import WarningDialog from './WarningDialog';
import Api from "../../services/http/api";

const GroupsAndUsers: React.FC = () => {
  const [value, setValue] = React.useState(0);
  const [openWarning, setOpenWarning] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [warningMessage, setWarningMessage] = React.useState('');
  const [auth, setAuth] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        // Perform authentication check
        const result = await Api.get('http://158.160.99.23/api/v1/auth/access');
        // Assuming the result.data.auth property indicates authentication status
        if (result.data == 'OK') {
          setAuth(true);
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        setAuth(false);
        setTitle('Ошибка проверки аутентификации');
        setWarningMessage('Пожалуйста, выполните вход в систему или убедитесь в налчии прав администратора');
        setOpenWarning(true);
      }
    };

    checkAuth();
  }, []);

  if (!auth) {
    // Render warning dialog if not authenticated
    return (
      <WarningDialog 
        open={openWarning} 
        onClose={() => setOpenWarning(false)} 
        title={title} 
        message={warningMessage}
      />
    );
  }

  return (
    
    
    <Box sx={{ width: '100%' }}>
      <WarningDialog open={openWarning} onClose={() => setOpenWarning(false)} title={title} message={warningMessage}/>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Редактирование пользователей" />
        <Tab label="Редактировние групп" />
        <Tab label="Управление группами и пользователями" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <UsersGrid/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GroupsGrid />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UserManagementPage />
      </TabPanel>
    </Box>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
};

export default GroupsAndUsers;
