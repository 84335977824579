import * as React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {VisualPage} from "./pages/VisualPage";
import {ConfigPage} from "./components/configPage/ConfigPage";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route
                        path="visual"
                        element={<VisualPage/>}
                    />
                    <Route
                        path="configuration"
                        element={<ConfigPage/>}
                    />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
