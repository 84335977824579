import React, { useEffect, useState } from "react";
import "./modal.css";
import DataGrid from '../table/data_grid';
import SelectedListItem from "../list/nodes_list";
import {JrpcServer} from "../../jrpc/server/0_0_1/types";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Paper from '@mui/material/Paper';

const generateUniqueId = () => {
    const timestamp = Date.now().toString(36);
    const randomNumber = Math.random().toString(36).substr(2);
    return timestamp + randomNumber;
};

const createTabelRows = (data: object): any[] => {
    return Object.entries(data).map(([name, data]) => ({id: generateUniqueId(), name, data }));
};

const Modal = (props: { modalNodeData: any, setActive: any, jRPCServer: JrpcServer}) => {
    const { modalNodeData, setActive, jRPCServer } = props;
    const [rows, setRows] = useState<any[]>([]);

    const setVisibleFalse = () => {
        console.log(modalNodeData)
        setActive({...modalNodeData, visible: false});
    }
        

    useEffect(() => {
        if (!modalNodeData.isGroup) {
            const newRows = createTabelRows(modalNodeData.nodeInfo);
            setRows(newRows);
        }
    }, [modalNodeData]);

    let header = null;
    let data_part = null;
    const aditionalColumns = [
        { 
            field: 'name', 
            headerName: 'Наименование', 
            editable: true,
            width: 200,
        },
        {
          field: 'data',
          headerName: 'Данные',
          editable: true,
          width: 200,
        },
    ];

    if (modalNodeData.isGroup) {
        header = (
            <div className="node_header">
                <Button variant="contained" size="small" onClick={() => setVisibleFalse()} startIcon={<ArrowBackIosIcon />}>Закрыть</Button>
                <div>
                    <p className="node_name">Групповой узел</p>
                    <p className="node_name">Тип: {modalNodeData.nodeName}</p>
                </div>
            </div>
        )
        data_part = (
            <div className="data-part group">
                <SelectedListItem data={modalNodeData} jRPCServer={props.jRPCServer}/>
            </div>
        )
    } else {
        header = (
            <div className="node_header">
                <Button variant="contained" size="small" onClick={() => setVisibleFalse()} startIcon={<ArrowBackIosIcon />}>Закрыть</Button>
                <div>
                    <p className="node_name">Тип: {modalNodeData.nodeName}</p>
                    <p className="node_name">Наименование: {modalNodeData.nodeInfo?.TAG}</p>
                </div>
            </div>
        )
        data_part = (
            <div className="data-part group">
                <DataGrid
                    rows={rows}
                    setRows={setRows}
                    aditionalColumns={aditionalColumns}
                    onRemoveNodeAttr={(attrName)=>{console.log(modalNodeData); jRPCServer.removeNodeAttr({node_name: modalNodeData.nodeName, attr_name: attrName})}}
                    onSetNodeAttr={(attrName, attrValue) => {console.log(attrValue); jRPCServer.setNodeAttr({node_name: modalNodeData.nodeName, attr_name: attrName, attr_value: attrValue})}}
                />
            </div>
        )
    }

    return (
        <Paper className={`modal ${modalNodeData.visible ? 'active' : ''}`}>
            <div className="modal__content" onClick={e => e.stopPropagation()}>
                {header}
                {data_part}
            </div>
        </Paper>
    );
};

export default Modal;