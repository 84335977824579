import React, {useState} from "react";
import {Link} from "react-router-dom";
import MobileDropdown from "./MobileDropdown";

const MobileMenuItems = (
    props: {
        menuItem: any,
        depthLevel: number,
        showMenu: boolean | undefined,
        setShowMenu: any | undefined
    }
) => {
    const {menuItem, depthLevel, showMenu, setShowMenu} = props;
    const [dropdown, setDropdown] = useState(false);

    const closeDropdown = () => {
        dropdown && setDropdown(false);
        showMenu && setShowMenu(false);
    };

    const toggleDropdown = (e: any) => {
        e.stopPropagation();
        setDropdown((prev) => !prev);
    };

    const onClickHandler = () => {
        console.log(menuItem);
        if (menuItem.function) {
            menuItem.function()
        }
    }

    return (
        <li className="menu-items" onClick={closeDropdown}>
            {menuItem.url && menuItem.submenu ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? "true" : "false"}>
                        <Link to={menuItem.url} onClick={closeDropdown}>
                            {menuItem.title}
                        </Link>
                        <div onClick={(e) => toggleDropdown(e)}>
                            {dropdown ? (
                                <span className="arrow-close"/>
                            ) : (
                                <span className="arrow"/>
                            )}
                        </div>
                    </button>
                    <MobileDropdown
                        depthLevel={depthLevel}
                        submenu={menuItem.submenu}
                        dropdown={dropdown}
                    />
                </>
            ) : !menuItem.url && menuItem.submenu ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? "true" : "false"}>
                        {menuItem.title}{" "}
                        <div onClick={(e) => toggleDropdown(e)}>
                            {dropdown ? (
                                <span className="arrow-close"/>
                            ) : (
                                <span className="arrow"/>
                            )}
                        </div>
                    </button>
                    <MobileDropdown
                        depthLevel={depthLevel}
                        submenu={menuItem.submenu}
                        dropdown={dropdown}
                    />
                </>
            ) : (
                <Link to={menuItem.url + location.search} onClick={() => onClickHandler()}>{menuItem.title}</Link>
            )}
        </li>
    );
};

export default MobileMenuItems;
