// eslint-disable-next-line @typescript-eslint/no-var-requires
export const validator = require('validator');

const Notif = {
    type: "object",
    properties: {
        jsonrpc: {type: "string"},
        method: {type: "string"},
        params: {type: "object"}
    },
    required: ["jsonrpc", "method"],
} as const;

const Req = {
    type: "object",
    properties: {
        id: {type: "string", format: "uuid"},
        jsonrpc: {type: "string"},
        method: {type: "string"},
        params: {type: "object"}
    },
    required: ["jsonrpc", "method", "id"],
} as const;

const Res = {
    type: "object",
    properties: {
        jsonrpc: {type: "string"},
        id: {type: "string", format: "uuid"},
        result: {type: "object"}
    },
    required: ["jsonrpc", "id", "result"],
} as const;

const Err = {
    type: "object",
    properties: {
        jsonrpc: {type: "string"},
        id: {type: "string", format: "uuid"},
        error: {
            type: "object",
            properties: {
                code: {
                    type: "number"
                },
                message: {
                    type: "string"
                }
            }
        }
    },
    required: ["jsonrpc", "id", "error"],
} as const;

export default {
    Req,
    Res,
    Notif,
    Err
}
